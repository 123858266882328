import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import AlternatingArticles from '../components/slices/AlternatingArticles/AlternatingArticles';

const WebinarTemplate = ({ data }) => {
  if (!data) return null;

  const webinarContent = data.prismicWebinar;
  const webinar = webinarContent.data || {};
  const allWebinarPosts = data.allPrismicWebinarPost.nodes;
  const onlyWebinarForms = allWebinarPosts.filter((post) => post.data.is_form && post.data.publish_on_website);

  const { lang, type, url } = webinarContent;
  const alternateLanguages = webinarContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const metaTitle = webinarContent.meta_title || 'CloudArmy Webinar';

  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={webinar.body} />
      <AlternatingArticles slice={onlyWebinarForms} />
    </Layout>
  );
};

export const query = graphql`
query webinarQuery($uid: String, $lang: String) {
  prismicWebinar(uid: {eq: $uid}, lang: {eq: $lang}) {
    url
    uid
    type
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    _previewable  
    data {
      section
      meta_title
      display_title {
        html
        richText
        text
      }
      body {
        ... on PrismicWebinarDataBodyFullWidthImageWithTextBelow {
          id
          slice_label
          primary {
            title_color
            title {
              text
              richText
              raw
              html
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            has_stream_buttons
            description_color
            description_position
            description {
              text
              richText
              raw
              html
            }
          }
          slice_type
        }
      }
    }
  }
  allPrismicWebinarPost(
      sort: { fields: data___date, order: DESC }
  ) {
    nodes {
      _previewable
      id
      url
      tags
      data {
        display_title {
          richText
          html
          text
        }
        date
        description {
          richText
          html
          text
        }
        is_form
        publish_on_website
        image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(height: 350, layout: CONSTRAINED, placeholder: NONE)
            }
          }
        }
      }
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
  prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
  }
  prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
  }
}
`;

export default withPrismicPreview(WebinarTemplate);
